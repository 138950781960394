import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import api from '../../api';

const ClientForm = () => {
    const [formData, setFormData] = useState({
        nom: '',
        email: '',
        telephone: '',
        adresse: '',
        ville: '',
        codePostal: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await api.post('/Clients/add', formData);
            if (response.data.status === 'OK') {
                alert('Client ajouté avec succès !');
                setFormData({
                    nom: '',
                    email: '',
                    telephone: '',
                    adresse: '',
                    ville: '',
                    codePostal: '',
                });
            } else {
                alert('Une erreur est survenue lors de l\'ajout du client.');
            }
        } catch (error) {
            alert('Erreur de réseau ou serveur.');
            console.error(error);
        }
    };

    return (
        <div class="content-wrapper">
            <div class="content-header">
                <div class="container">
                    <div class="row mb-2">
                        <div class="col-sm-6 d-md-flex gap-2">
                                <Link to="/client" className="btn btn-sm btn-outline-primary"><i class="fas fa-arrow-left"></i> Retour</Link>
                            <h1>Ajouter un Client</h1>
                        </div>

                    </div>
                </div>
            </div>
            <div class="content">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="card">
                                <div class="card-body">
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formNomClient" className="mb-3">
                                            <Form.Label>Nom du Client</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Entrez le nom du client"
                                                name="nom"
                                                value={formData.nom}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="formEmailClient" className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Entrez l'email du client"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="formTelephoneClient" className="mb-3">
                                            <Form.Label>Téléphone</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Entrez le téléphone du client"
                                                name="telephone"
                                                value={formData.telephone}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="formAdresseClient" className="mb-3">
                                            <Form.Label>Adresse</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Entrez l'adresse du client"
                                                name="adresse"
                                                value={formData.adresse}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="formVilleClient" className="mb-3">
                                            <Form.Label>Ville</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Entrez la ville du client"
                                                name="ville"
                                                value={formData.ville}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="formCodePostalClient" className="mb-3">
                                            <Form.Label>Code Postal</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Entrez le code postal"
                                                name="codePostal"
                                                value={formData.codePostal}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Button variant="primary" type="submit"> Valider</Button>
                                    </Form>

                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientForm;
