import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

function SideBar() {
  return (
    <Fragment>

      <aside class="main-sidebar sidebar-dark-primary elevation-4">

        <a href="#" class="brand-link">
          {/* <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3"/> */}
          <span class="brand-text font-weight-bold ">EasyKommerce V.02</span>
        </a>


        <div class="sidebar">

          <div class="user-panel mt-3 pb-3 mb-3 d-flex">
            <div class="image">
              {/* <img src="dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image" /> */}
            </div>
            <div class="info">
              <a href="#" class="d-block text-underline-none">{sessionStorage.getItem("userName")} </a>
            </div>
          </div>



          <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

              <li class="nav-item menu-open">
                <Link to="/" className="nav-link">
                  <i class="nav-icon fas fa-tachometer-alt"></i>
                  <p>
                    Tableau de Bord
                  </p>
                </Link>
              </li>
              <li class="nav-item">
                <Link to="/produit" className="nav-link">
                  <i class="nav-icon fas fa-list-alt"></i>
                 
                  <p>
                    Produit
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/client" className="nav-link">
                  <i class="nav-icon  fas fa-users"></i>
                  <p>Client</p>
                </Link>
              </li>
              <li class="nav-item">
                <Link to="/stock" className="nav-link">
                  <i class="nav-icon fas fa-boxes"></i>
                  <p>
                  Stock
                  </p>
                </Link>
              </li>
              {/* <li class="nav-item">
                <Link to="/posts  " className="nav-link">
                  <i class="nav-icon fas fa-cash-register"></i>
                  <p>
                    Vente
                  </p>
                </Link>
              </li> */}
      
       
              <li className="nav-item">
                <Link to="/fournisseur" className="nav-link">
                  <i class="nav-icon  fas fa-people-carry"></i>
                 
                  <p>Fournisseur</p>
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link to="/mes-documents" className="nav-link">
                  <i class="nav-icon  fas fa-cube"></i>
                  <p>Commande</p>
                </Link>
              </li> */}
              <li className="nav-item">
                <Link to="/depense" className="nav-link">
                  <i class="nav-icon  fas fa-wallet"></i>
                  {/* <i class="fas fa-wallet"></i> */}
                  <p>Depense</p>
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/add-magasin" className="nav-link">
                  <i class="nav-icon  fas fa-store"></i>
                  <p>Magasins</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/rapport" className="nav-link">
                  <i class="nav-icon far fa-chart-bar"></i>
                  {/* <i class="far fa-chart-bar"></i> */}
                  <p>Rapport</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/parametre" className="nav-link">
                  <i class="nav-icon  fas fa-cogs"></i>
                  <p>Paramètres</p>
                </Link>
              </li>
            </ul>
          </nav>

        </div>

      </aside>

    </Fragment>
  )
}

export default SideBar