import React, { useMemo } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineElement, PointElement } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';

// Configuration Chart.js
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const Dashboard = () => {
    // Simulate data from your database (this data can come from an API)
    const totalSales = 120000;
    const totalOrders = 320;
    const totalStock = 15000;
    const totalStores = 5;

    const salesData = useMemo(() => ({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [
            {
                label: 'Ventes en Volume',
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(75,192,192,0.6)',
                hoverBorderColor: 'rgba(75,192,192,1)',
                data: [15000, 12500, 10000, 14000, 15500, 12000],
            },
        ],
    }), []); // Assurez-vous de ne pas inclure d'états qui changent ici

    const stockData = useMemo(() => ({
        labels: ['Produit A', 'Produit B', 'Produit C', 'Produit D'],
        datasets: [
            {
                label: 'Quantité en stock',
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: [2000, 3000, 1000, 4000],
            },
        ],
    }), []); // Assurez-vous de ne pas inclure d'états qui changent ici

    return (
        <div class="content-wrapper">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0">Tableau de Bord</h1>
                        </div>

                    </div>
                </div>
            </div>
            <div class="content">
                <div class="container-fluid">
                  
                                    {/* Statistiques principales */}
                                    <Row>
                                        <Col md={3}>
                                            <Card className="text-center">
                                                <Card.Body>
                                                    <Card.Title>Total des Ventes</Card.Title>
                                                    <Card.Text>{totalSales} FCFA</Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col md={3}>
                                            <Card className="text-center">
                                                <Card.Body>
                                                    <Card.Title>Total des Commandes</Card.Title>
                                                    <Card.Text>{totalOrders} Commandes</Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col md={3}>
                                            <Card className="text-center">
                                                <Card.Body>
                                                    <Card.Title>Total en Stock</Card.Title>
                                                    <Card.Text>{totalStock} Articles</Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col md={3}>
                                            <Card className="text-center">
                                                <Card.Body>
                                                    <Card.Title>Total des Magasins</Card.Title>
                                                    <Card.Text>{totalStores} Magasins</Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>

                                    {/* Section des graphiques */}
                                    <Row className="my-4">
                                        <Col md={6}>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>Évolution des Ventes</Card.Title>
                                                    <Bar data={salesData} options={{ maintainAspectRatio: true }} />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col md={6}>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>État des Stocks</Card.Title>
                                                    <Line data={stockData} options={{ maintainAspectRatio: true }} />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>

                                    {/* Section des magasins */}
                                    <Row>
                                        <Col>
                                            <Card className="my-3">
                                                <Card.Body>
                                                    <Card.Title>Magasins</Card.Title>
                                                    <Button variant="primary" className="mt-2">Gérer les Magasins</Button>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col>
                                            <Card className="my-3">
                                                <Card.Body>
                                                    <Card.Title>Commandes Fournisseurs</Card.Title>
                                                    <Button variant="primary" className="mt-2">Voir les Commandes</Button>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>

                                    {/* Section des rapports */}
                                    <Row>
                                        <Col>
                                            <Card className="my-3">
                                                <Card.Body>
                                                    <Card.Title>Rapports de Vente</Card.Title>
                                                    <Button variant="primary" className="mt-2">Voir les Rapports</Button>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </div>


                        </div>

          
    );
};

export default Dashboard;
