import React, { Fragment, useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
// import { Button } from 'primereact/button';
import api from '../../api';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import './dragDropStyle.css';
import Select from 'react-select'
import SousCategory from './getSousCategory';
function ProduitForm() {
    const [formData, setFormData] = useState({
        nom: '',
        description: '',
        prix: '',
        categorie: '',
        scategorie: '',
        seuilStock:0,
        stock:0,
    });
    const [prodImage, setProdImage] = useState();
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null); // For image preview

    // Function to handle file drop
    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        setImage(file);
        setImagePreview(URL.createObjectURL(file)); // Generate image preview URL
    };

    // Configuration for the dropzone
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: false,
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });


    };

    const handleFile = (e) => {
        setProdImage(e.target.files[0]);
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const submitData = new FormData();
        submitData.append("nom", formData.nom);
        submitData.append("description", formData.description);
        submitData.append("prix", formData.prix);
        submitData.append("categorie", formData.categorie);
        submitData.append("scategorie", formData.scategorie);
        submitData.append("stock", formData.stock);
        submitData.append("seuilStock", formData.seuilStock);
        submitData.append("prod_image", prodImage);  // Image file
        
        try {
            const response = await api.post(`/produits/add`, submitData);
            if (response.data.status === 'OK') {
                alert('Produit créé avec succès !');
                // Réinitialiser le formulaire
                setFormData({ ...formData, nom: '', description: '', prix: '', stock: '', seuilStock: '' });
                setProdImage(null);
            } else {
                alert('Erreur lors de la création du produit.');
            }
        } catch (error) {
            console.error('Erreur:', error);
            alert('Erreur de réseau ou du serveur.');
        }
    };
    


    const handleCategoryChange = (selectedOption) => {
        setFormData({
            ...formData,
            categorie: selectedOption.value
        });
    };
    const handleSubCategoryChange = (selectedOption) => {
        setFormData({
            ...formData,
            scategorie: selectedOption.value
        });
    };
    const [categories, setCgategories] = useState([]);
    const fetchCategories = async () => {
        const response = await api.get(`CategorieProduits/getcategorieProduits`);
        if (response.data.status === 'OK') {
            setCgategories(response.data.result.categorie);
        }
    };
    const [sousCategories, setSousCgategories] = useState([]);

    const fetchSousCategories = async () => {
        const response = await api.get(`/souscategorieProduits/getSouscategorieProduits/` + formData.categorie);
        if (response.data.status === 'OK') {
            setSousCgategories(response.data.result.Subcategorie)
        }
    }

    useEffect(() => {
        if (formData.categorie) {
            fetchSousCategories();
        }
        fetchCategories();
    }, [formData.categorie]);
    return (
        <Fragment>
            <div class="content-wrapper">
                <div class="content-header">
                    <div class="container">
                        <div class="row mb-2">
                            <div class="col-sm-6 d-md-flex gap-2">
                                <Link to="/produit" className="btn btn-light"><i class="fas fa-arrow-left"></i> Retour</Link>
                                <h1 class="m-0">Ajout de nouveau produit</h1>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="content">
                    <div class="container">
                        <div className="justify-content-md-center">
                            <div class="col">
                                <div class="card">
                                    <div class="card-body">
                                        <Form onSubmit={handleSubmit} enctype="multipart/form-data">
                                            <Form.Group controlId="formNomProduit" className="mb-3">
                                                <Form.Label>Nom du Produit</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Entrez le nom du produit"
                                                    name="nom"
                                                    value={formData.nom}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group>

                                            <Form.Group controlId="formDescriptionProduit" className="mb-3">
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder="Entrez la description du produit"
                                                    name="description"
                                                    value={formData.description}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group>

                                            <Form.Group controlId="formPrixProduit" className="mb-3">
                                                <Form.Label>Prix</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Entrez le prix"
                                                    name="prix"
                                                    value={formData.prix}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group>
                                            <Row>
                                                <Col>
                                                    <Form.Group controlId="formCategorieProduit" className="mb-3">
                                                        <Form.Label>Catégorie</Form.Label>
                                                        <Select
                                                            options={categories}
                                                            onChange={handleCategoryChange}
                                                            placeholder="Sélectionnez une categorie..."
                                                            isSearchable
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group controlId="formSousCategorieProduit" className="mb-3">
                                                        <Form.Label>Sous Catégorie</Form.Label>
                                                        {/* <SousCategory idCategory={formData.categorie} /> */}
                                                        <Select
                                                            options={sousCategories}
                                                            onChange={handleSubCategoryChange}
                                                            placeholder="Sélectionnez une sous-categorie..."
                                                            isSearchable
                                                        />

                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group controlId="formStockProduit" className="mb-3">
                                                        <Form.Label>Quantité en Stock</Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Entrez la quantité en stock"
                                                            name="stock"
                                                            value={formData.stock}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group controlId="formStockProduit" className="mb-3">
                                                        <Form.Label>Seuil de stock</Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Entrez le seuil de stock"
                                                            name="seuilStock"
                                                            value={formData.seuilStock}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>




                                            {/* Drag and Drop for Image Upload */}
                                            {/* <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}   mb-5`}>
                                                <input {...getInputProps()} />
                                                {isDragActive ? (
                                                    <p>Déposez l'image ici...</p>
                                                ) : (
                                                    <p>Glissez et déposez une image ici</p>
                                                )}
                                            </div>

                                           
                                            {imagePreview && (
                                                <div className="dropzone-preview">
                                                    <img src={imagePreview} className='imagePreview' alt="Aperçu de l'image téléchargée" />
                                                </div>
                                            )} */}
                                            {/* */}
                                            <Form.Group controlId="formImageProduit" className="mb-3">
                                                <Form.Label>Image du Produit</Form.Label>
                                                <Form.Control type="file" required onChange={handleFile} />
                                            </Form.Group>

                                            {/* <Form.Group controlId="formFile" className="mb-3">
                                                <Form.Label>Image</Form.Label>
                                                <Form.Control type="file" required onChange={handleFile} />
                                            </Form.Group> */}

                                            <Button variant="primary" type="submit">
                                                Ajouter le Produit
                                            </Button>
                                        </Form>


                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ProduitForm 