import React, { Fragment } from 'react'
import { Button } from 'primereact/button';
function Stock() {
    return (
        <Fragment>
            <div class="content-wrapper">
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h1 class="m-0">Stock</h1>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="content">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">Stock</h5>

                        

                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Stock