import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import api from '../../api';
import { Button } from 'bootstrap';
import { Link } from 'react-router-dom';
function CusttomersList() {
    const [customers, setCustomers] = useState([]);

    const fetchListCustomers = async () => {
        const response = await api.get('/Clients/');
        if (response.data.status === 'OK') {
            setCustomers(response.data.result.clients)
        }
    }
    useEffect(() => {
        fetchListCustomers();
    }, []);

    return (
        <>
            <div class="content-wrapper">
                <div class="content-header">
                    <div class="container">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h2>Liste de Clients</h2>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="card">
                                    <div className='p-3'><Link to={'/add-client'} class='btn btn-outline-primary'><i class="fas fa-plus-circle"></i> Ajout Client</Link></div>
                                    <div class="card-body">
                                        <DataTable value={customers} tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="nom_client" header="Client"></Column>
                                            <Column field="email" header="Email"></Column>
                                            <Column field="telephone" header="Téléphone"></Column>
                                            <Column field="adresse" header="Adresse"></Column>
                                        </DataTable>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div></>
    )
}

export default CusttomersList