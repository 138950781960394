import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';

const FournisseurForm = () => {
    const [formData, setFormData] = useState({
        nom: '',
        email: '',
        telephone: '',
        adresse: '',
        type: '',
        description: '',
    });

    const typesFournisseur = ['Local', 'International'];

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('http://localhost/api/fournisseurs/add', formData);
            if (response.data.status === 'OK') {
                alert('Fournisseur ajouté avec succès !');
                setFormData({
                    nom: '',
                    email: '',
                    telephone: '',
                    adresse: '',
                    type: '',
                    description: '',
                });
            } else {
                alert('Une erreur est survenue lors de l\'ajout du fournisseur.');
            }
        } catch (error) {
            alert('Erreur de réseau ou serveur.');
            console.error(error);
        }
    };

    return (
        <div class="content-wrapper">
            <div class="content-header">
                <div class="container">
                    <div class="row mb-2">
                        <div class="col-sm-6 d-md-flex gap-2">
                            <Link to="/fournisseur" className="btn btn-light"><i class="fas fa-arrow-left"></i> Retour</Link>
                            <h1>Ajouter un Fournisseur</h1>
                        </div>

                    </div>
                </div>
            </div>

            <div class="content">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="card">
                                <div class="card-body">

                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formNomFournisseur" className="mb-3">
                                            <Form.Label>Nom du Fournisseur</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Entrez le nom du fournisseur"
                                                name="nom"
                                                value={formData.nom}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="formEmailFournisseur" className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Entrez l'email du fournisseur"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="formTelephoneFournisseur" className="mb-3">
                                            <Form.Label>Téléphone</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Entrez le téléphone du fournisseur"
                                                name="telephone"
                                                value={formData.telephone}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="formAdresseFournisseur" className="mb-3">
                                            <Form.Label>Adresse</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Entrez l'adresse du fournisseur"
                                                name="adresse"
                                                value={formData.adresse}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="formTypeFournisseur" className="mb-3">
                                            <Form.Label>Type de Fournisseur</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="type"
                                                value={formData.type}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Sélectionnez un type</option>
                                                {typesFournisseur.map((type, index) => (
                                                    <option key={index} value={type}>
                                                        {type}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formDescriptionFournisseur" className="mb-3">
                                            <Form.Label>Description (Optionnel)</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                placeholder="Entrez une description"
                                                name="description"
                                                value={formData.description}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Button variant="primary" type="submit">
                                            Ajouter le Fournisseur
                                        </Button>
                                    </Form>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default FournisseurForm;
