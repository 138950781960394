import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';

const DepenseForm = () => {
    const [formData, setFormData] = useState({
        nom: '',
        montant: '',
        categorie: '',
        date: '',
        description: '',
        fournisseur: '',
    });

    const categories = ['Alimentation', 'Transport', 'Logement', 'Divertissement', 'Santé', 'Autres'];

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('http://localhost/api/depenses/add', formData);
            if (response.data.status === 'OK') {
                alert('Dépense ajoutée avec succès !');
                setFormData({
                    nom: '',
                    montant: '',
                    categorie: '',
                    date: '',
                    description: '',
                    fournisseur: '',
                });
            } else {
                alert('Une erreur est survenue lors de l\'ajout de la dépense.');
            }
        } catch (error) {
            alert('Erreur de réseau ou serveur.');
            console.error(error);
        }
    };
    return (
        <>  <div class="content-wrapper">
            <div class="content-header">
                <div class="container">
                    <div class="row mb-2">
                        <div class="col-sm-6 d-md-flex gap-2">
                                <Link to="/depense" className="btn btn-light"><i class="fas fa-arrow-left"></i> Retour</Link>
                            <h1 class="m-0">Depenses</h1>
                        </div>

                    </div>
                </div>
            </div>

            <div class="content">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="card">
                                <div class="card-body">

                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formNomDepense" className="mb-3">
                                            <Form.Label>Nom de la Dépense</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Entrez le nom de la dépense"
                                                name="nom"
                                                value={formData.nom}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="formMontantDepense" className="mb-3">
                                            <Form.Label>Montant</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Entrez le montant de la dépense"
                                                name="montant"
                                                value={formData.montant}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="formCategorieDepense" className="mb-3">
                                            <Form.Label>Catégorie de Dépense</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="categorie"
                                                value={formData.categorie}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Sélectionnez une catégorie</option>
                                                {categories.map((cat, index) => (
                                                    <option key={index} value={cat}>
                                                        {cat}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group controlId="formDateDepense" className="mb-3">
                                            <Form.Label>Date de la Dépense</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="date"
                                                value={formData.date}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="formDescriptionDepense" className="mb-3">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                placeholder="Entrez une description"
                                                name="description"
                                                value={formData.description}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="formFournisseurDepense" className="mb-3">
                                            <Form.Label>Fournisseur (Optionnel)</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Entrez le nom du fournisseur"
                                                name="fournisseur"
                                                value={formData.fournisseur}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Button variant="primary" type="submit">
                                            Ajouter la Dépense
                                        </Button>
                                    </Form>


                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </div></>
    )
}

export default DepenseForm