import React, { Fragment } from 'react'
import Navigation from './Navigation'
import SideBar from './SideBar'
import Footer from './Footer'
import Navbar from './Navbar'
import 'primeicons/primeicons.css';
import"primereact/resources/themes/bootstrap4-light-blue/theme.css";
import { useLocation } from 'react-router'
import Login from './components/account/login'
import Register from './components/account/createAccount'
import ForgetPassword from './components/account/forgetPassword'

export default function App() {
  const location = useLocation();
  if (location.pathname == "/create-account") {
    return <Register />;
  }
  else if (location.pathname == "/logout" || location.pathname == "/login") {
    sessionStorage.clear(); // Remove all saved data from sessionStorage
    return <Login />;
  }
  else if (location.pathname == "/password-forget") {
    sessionStorage.clear(); // Remove all saved data from sessionStorage
    return <ForgetPassword />;
  }
  else if (sessionStorage.getItem("userName") == null) {
    return <Login />;
  } else {
  return (
    <Fragment>
        <Navbar />
        <SideBar />
        <Navigation />
        <Footer />
    </Fragment>
  )
}
}

