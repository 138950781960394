import React, { useState } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import './loginStyle.css'
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, Navigate } from 'react-router-dom';
import api from '../../api';
const Login = () => {
    // const [recaptchaToken, setRecaptchaToken] = useState(null);

    // const handleRecaptchaChange = (token) => {
    //   setRecaptchaToken(token);
    // };*
    const [redirect, setRedirect] = useState(false);
    const [isloading, setIsloading] = useState(false);
    const [form, setForm] = useState({
        email: "",
        password: "",
    });
    const handleChange = (field, value) => {
        setForm((state) => ({ ...state, [field]: value }));
    };


    const handleSave = (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append("email", form.email);
        data.append("password", form.password);
        setIsloading(true);
        api.post(`Users/login`, data).then((res) => {
            if (res.data.result.feedback === "success") {
                //sessionStorage.setItem("accessToken", res.data.result.token);
                sessionStorage.setItem("userId", res.data.result.payload.id);
                sessionStorage.setItem("email", res.data.result.payload.email);
                sessionStorage.setItem("userName", res.data.result.payload.username);
                sessionStorage.setItem("ClientId", res.data.result.payload.ClientId);
                setRedirect(true);
            } else if (res.data.result.feedback === "failed") {
                setIsloading(false);

            }
        });
    };
    if (redirect) return <Navigate to="/" />;
    const spiner = '<div class="spinner-grow spinner-grow-sm" role="status"></div>'
    return (
        <>
            <Container fluid className="vh-100 d-flex align-items-center justify-content-center">
                <Row className="w-100">
                    <Col md={4} className="mx-auto">
                        <h1 className='text-center'><span className='text-blue font-weight-bold'>Easy</span><span className='text-red font-weight-bold'>K</span>ommerce</h1>
                        <Card className="shadow-sm border-0">
                            <Card.Body>
                                <div className="text-center">
                                    {/* <img
                  src="https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg"
                  alt="Google Logo"
                  style={{ width: '150px' }}
                /> */}

                                    <h2 className="mt-4 mb-3 font-weight-lighter subTitle">Se connecter à Easykommerce</h2>

                                </div>
                                <Form onSubmit={handleSave}>
                                    <Form.Group controlId="formEmail" className="mb-3">
                                        <Form.Label>Email ou téléphone</Form.Label>
                                        <Form.Control type="email" placeholder="Adresse email ou neméro de tétéphone"
                                            onChange={(event) =>
                                                handleChange("email", event.target.value)
                                            } />
                                    </Form.Group>
                                    <Form.Group controlId="formEmail" className="mb-3">
                                        <Form.Label>Mot de passe</Form.Label>
                                        <Form.Control type="password" placeholder="Adresse email"
                                            onChange={(event) =>
                                                handleChange("password", event.target.value)
                                            } />
                                    </Form.Group>

                                    <Button variant="primary" className="w-100 font-weight-bold" type="submit">
                                        Se connecter
                                    </Button>

                                    <div className="text-center mt-3">
                                        <a href="#">Mot de passe oublié</a>
                                    </div>
                                </Form>

                            </Card.Body>
                        </Card>

                        <div className="text-center mt-4">
                            <p>
                                Vous n'avez de compte ?  <Link to={'/create-account'}>Créer un compte</Link>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default Login