import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Magasins from './components/magasins';
import Settings from './components/parametres';
import Stock from './components/stocks';
import ReportsPage from './components/rapports';
import ProductList from './components/products';
import ProduitForm from './components/products/CreateProduct';
import CusttomersList from './components/customers';
import ClientForm from './components/customers/CreateCustomers';
import FournisseursList from './components/fournisseurs';
import FournisseurForm from './components/fournisseurs/CreateFournisseur';
import DepensesList from './components/depenses';
import DepenseForm from './components/depenses/CreateDepenses';

function Navigation() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/add-magasin" element={<Magasins />} />
      <Route path="/add-produit" element={<ProduitForm />} />
      <Route path="/produit" element={<ProductList />} />
      <Route path="/add-depense" element={<DepenseForm />} />
      <Route path="/depense" element={<DepensesList />} />
      <Route path="/add-fournisseur" element={<FournisseurForm />} />
      <Route path="/fournisseur" element={<FournisseursList />} />
      <Route path="/add-client" element={<ClientForm />} />
      <Route path="/client" element={<CusttomersList />} />
      <Route path="/parametre" element={<Settings />} />
      <Route path="/stock" element={<Stock />} />
      <Route path="/rapport" element={<ReportsPage />} />
      {/* ReportsPage */}
    </Routes>
  )
}

export default Navigation