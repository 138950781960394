import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import api from '../../api';
import { Button } from 'bootstrap';
import { Link } from 'react-router-dom';

function FournisseursList() {
    const [fournisseurs, setFournisseurs] = useState([]);

    const fetchListFournissuers = async () => {
        const response = await api.post('/fournisseurs/');
        if (response.data.status === 'OK') {
            setFournisseurs(response.data.result.fournisseurs)

        }
    }
    useEffect(() => {
        fetchListFournissuers();
    }, []);

    return (
        <>
            <div class="content-wrapper">
                <div class="content-header">
                    <div class="container">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h2>Liste de Fournisseurs</h2>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="card">
                                    <div className='p-3'><Link to={'/add-fournisseur'} class='btn btn-light'><i class="fas fa-plus-circle"></i> Ajout Fournissuer</Link></div>
                                    <div class="card-body">
                                        <DataTable value={fournisseurs} tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="nom_fournisseur" header="Fournisseur"></Column>
                                            <Column field="email" header="Email"></Column>
                                            <Column field="telephone" header="Téléphone"></Column>
                                            <Column field="adresse" header="Adresse"></Column>
                                        </DataTable>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div></>
    )
}

export default FournisseursList