import React, { Fragment, useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
// import { Button } from 'primereact/button';
import api from '../../api';
function Magasins() {
    const [formData, setFormData] = useState({
        nom: '',
        adresse: '',
        telephone: '',
        ville: '',
        codePostal: '',
        pays: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await api.post(`/magasins/add`, formData);
            if (response.data.status === 'OK') {
                alert('Magasin créé avec succès !');
                setFormData({
                    nom: '',
                    adresse: '',
                    telephone: '',
                    ville: '',
                    codePostal: '',
                    pays: '',
                });
            } else {
                alert('Une erreur est survenue.');
            }
        } catch (error) {
            alert('Erreur de réseau ou du serveur.');
            console.error(error);
        }
    };
    return (
        <Fragment>
            <div class="content-wrapper">
                <div class="content-header">
                    <div class="container">
                        <div class="row mb-2">
                            <div class="col-sm-6">
                                <h1 class="m-0">Magasins</h1>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="content">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="card">
                                    <div class="card-body">

                                        <Form onSubmit={handleSubmit}>
                                            <Form.Group controlId="formNomMagasin" className="mb-3">
                                                <Form.Label>Nom du Magasin</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Entrez le nom du magasin"
                                                    name="nom"
                                                    value={formData.nom}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group>

                                            <Form.Group controlId="formAdresseMagasin" className="mb-3">
                                                <Form.Label>Adresse</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Entrez l'adresse"
                                                    name="adresse"
                                                    value={formData.adresse}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group>

                                            <Form.Group controlId="formTelephoneMagasin" className="mb-3">
                                                <Form.Label>Téléphone</Form.Label>
                                                <Form.Control
                                                    type="tel"
                                                    placeholder="Entrez le téléphone"
                                                    name="telephone"
                                                    value={formData.telephone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group>

                                            <Form.Group controlId="formVilleMagasin" className="mb-3">
                                                <Form.Label>Ville</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Entrez la ville"
                                                    name="ville"
                                                    value={formData.ville}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group>

                                            <Form.Group controlId="formCodePostalMagasin" className="mb-3">
                                                <Form.Label>Code Postal</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Entrez le code postal"
                                                    name="codePostal"
                                                    value={formData.codePostal}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group>

                                            <Form.Group controlId="formPaysMagasin" className="mb-3">
                                                <Form.Label>Pays</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Entrez le pays"
                                                    name="pays"
                                                    value={formData.pays}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </Form.Group>

                                            <Button variant="primary" type="submit">
                                                Créer le Magasin
                                            </Button>
                                        </Form>

                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Magasins