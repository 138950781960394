import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import api from '../../api';
import { Link } from 'react-router-dom';
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [newProduct, setNewProduct] = useState({
    id: null,
    name: '',
    category: '',
    price: 0,
    stock: 0,
    rating: 0,
  });
  const categories = ['Catégorie 1', 'Catégorie 2', 'Catégorie 3'];

  const fectchProductList = async () => {
    const response = await api.post('/produits/index');
    if (response.data.status === 'OK') {
      setProducts(response.data.result.produits)

    }
  }
  useEffect(() => {
    // Simuler la récupération des données
    fectchProductList();
  }, []);

  // Ouvrir le formulaire pour ajouter un nouveau produit
  const openNew = () => {
    setNewProduct({
      id: null,
      name: '',
      category: '',
      price: 0,
      stock: 0,
      rating: 0,
    });
    setProductDialog(true);
  };

  // Ouvrir le formulaire pour éditer un produit
  const editProduct = (product) => {
    setSelectedProduct(product);
    setNewProduct({ ...product });
    setProductDialog(true);
  };

  // Sauvegarder ou mettre à jour un produit
  const saveProduct = () => {
    if (newProduct.id) {
      const updatedProducts = products.map((product) =>
        product.id === newProduct.id ? newProduct : product
      );
      setProducts(updatedProducts);
    } else {
      setNewProduct((prev) => ({ ...prev, id: products.length + 1 }));
      setProducts([...products, { ...newProduct, id: products.length + 1 }]);
    }
    setProductDialog(false);
  };

  // Supprimer un produit
  const confirmDeleteProduct = (product) => {
    setSelectedProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteProduct = () => {
    const updatedProducts = products.filter(
      (product) => product.id !== selectedProduct.id
    );
    setProducts(updatedProducts);
    setDeleteProductDialog(false);
    setSelectedProduct(null);
  };

  // Actions (CRUD : Voir, Modifier, Supprimer un produit)
  const actionBodyTemplate = (rowData) => {
    return (
      <>
        {/* <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => editProduct(rowData)}
        /> */}
          <Button icon="pi pi-pencil" rounded outlined className="mr-1 button-rounded " onClick={() => editProduct(rowData.id)} />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => confirmDeleteProduct(rowData)}
        />
      </>
    );
  };

  // Filtres personnalisés
  const categoryFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={categories}
        onChange={(e) => options.filterCallback(e.value)}
        placeholder="Sélectionner une catégorie"
        className="p-column-filter"
      />
    );
  };

  // Dialog de suppression de produit
  const deleteProductDialogFooter = (
    <>
      <Button
        label="Non"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setDeleteProductDialog(false)}
      />
      <Button
        label="Oui"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteProduct}
      />
    </>
  );

  return (
    <>
      <div class="content-wrapper">
        <div class="content-header">
          <div class="container">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1 class="m-0">Liste de Produits</h1>
              </div>

            </div>
          </div>
        </div>

        <div class="content">
          <div class="container">
            <div className="justify-content-md-center">
              <div class="col">
                <div class="card  shadow-sm border-0">
                  <div className="p-col-12">
                    <div title="Liste des Produits" style={{ marginBottom: '2em' }} className='p-3'>
                      {/* <Link to={'/add-produit'} className='btn btn-primary'><i className='pi-plus-circle'></i>Ajouter un Produit</Link> */}
                      <Link to="/add-produit" className="btn btn-outline-primary"><i class="nav-icon fas fa-plus"></i> Ajout de Produit</Link>
               </div>
                    <div class="card-body">
                      <DataTable
                        value={products}
                        paginator
                        rows={5}
                        responsiveLayout="scroll"
                        filterDisplay="row"
                      >

                        <Column
                          field="nom_nroduit"
                          header="Nom du Produit"
                          sortable
                          filter
                          filterPlaceholder="Chercher par nom"
                        ></Column>
                        <Column
                          field="categorie_produit.title"
                          header="Catégorie"
                          sortable
                          filter
                          filterElement={categoryFilterTemplate}
                        ></Column>
                        <Column
                          field="prix"
                          header="Prix (FCFA)"
                          sortable
                        ></Column>

                        <Column header="Action" body={actionBodyTemplate}></Column>
                      </DataTable>
                    </div>
                  </div>


                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Dialog pour ajouter ou modifier un produit */}
      <Dialog
        visible={productDialog}
        style={{ width: '450px' }}
        header={newProduct.id ? 'Modifier le Produit' : 'Nouveau Produit'}
        modal
        onHide={() => setProductDialog(false)}
        footer={
          <>
            <Button
              label="Annuler"
              icon="pi pi-times"
              className="p-button-text"
              onClick={() => setProductDialog(false)}
            />
            <Button
              label="Sauvegarder"
              icon="pi pi-check"
              className="p-button-text"
              onClick={saveProduct}
            />
          </>
        }
      >
        <div className="p-field">
          <label htmlFor="name">Nom</label>
          <InputText
            id="name"
            value={newProduct.name}
            onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
            required
          />
        </div>
        <div className="p-field">
          <label htmlFor="category">Catégorie</label>
          <InputText
            id="category"
            value={newProduct.category}
            onChange={(e) => setNewProduct({ ...newProduct, category: e.target.value })}
            required
          />
        </div>
        <div className="p-field">
          <label htmlFor="price">Prix (€)</label>
          <InputText
            id="price"
            value={newProduct.price}
            onChange={(e) => setNewProduct({ ...newProduct, price: parseFloat(e.target.value) })}
            required
          />
        </div>
        <div className="p-field">
          <label htmlFor="stock">Stock</label>
          <InputText
            id="stock"
            value={newProduct.stock}
            onChange={(e) => setNewProduct({ ...newProduct, stock: parseInt(e.target.value, 10) })}
            required
          />
        </div>
      </Dialog>

      {/* Dialog pour la confirmation de suppression */}
      <Dialog
        visible={deleteProductDialog}
        style={{ width: '450px' }}
        header="Confirmation de Suppression"
        modal
        footer={deleteProductDialogFooter}
        onHide={() => setDeleteProductDialog(false)}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" />
          {selectedProduct && (
            <span>
              Voulez-vous vraiment supprimer <b>{selectedProduct.name}</b> ?
            </span>
          )}
        </div>
      </Dialog>

    </>
  );
};

export default ProductList;
