import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import './accountStyle.css'
import { Link } from 'react-router-dom';
import api from '../../api';
function Register() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        username: '',
        storeName: '',
        phone: ''
    });
    const [formErrors, setFormErrors] = useState({});
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [loading, setLoading] = useState(false);

    // Validation des champs
    const validate = () => {
        let errors = {};

        if (!formData.firstName) errors.firstName = "Votre prénom requis";
        if (!formData.lastName) errors.lastName = "Votre nom est requis";
        // Validation du numéro de téléphone : il doit contenir exactement 10 chiffres
        if (!formData.phone) {
            errors.phone = "Votre numéro de téléphone est requis";
        } else if (!/^\d{10}$/.test(formData.phone)) {
            errors.phone = "Le numéro de téléphone doit comporter exactement 10 chiffres";
        }
        if (!formData.username) {
            errors.username = "Votre adresse email est requis";
        } else if (!/\S+@\S+\.\S+/.test(formData.username)) {
            errors.username = "Adresse email invalide";
        }
        if (!formData.storeName) {
            errors.storeName = "Le nom de votre entreprise est requis";
        } else if (formData.storeName.length < 2) {
            errors.storeName = "Le nom de votre entreprise n'est pas valide";
        }
        // if (formData.password !== formData.confirmPassword) {
        //     errors.confirmPassword = "Passwords do not match";
        // }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Gestion des changements dans le formulaire
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Gestion de reCAPTCHA
    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    // Gestion de la soumission du formulaire
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Valider les champs du formulaire
        if (!validate()) return;

        // if (!recaptchaToken) {
        //     alert("Please complete the reCAPTCHA.");
        //     return;
        // }

        setLoading(true);
        try {
            // Soumettre les données via Axios
            const response = await api.post('/clients/add/', formData);
            setFormData({
                firstName: '',
                lastName: '',
                username: '',
                storeName: '',
                phone: ''
            })
            console.log("Form successfully submitted:", response.data);
            alert("Account successfully created!");
        } catch (error) {
            console.error("Error submitting the form:", error);
            alert("An error occurred during form submission. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container fluid className="vh-100 d-flex align-items-center justify-content-center bg-light">
            <Row className="w-100">
                <Col md={6} lg={4} className="mx-auto">
                    <h1 className='text-center'><span className='text-blue font-weight-bold'>Easy</span><span className='text-red font-weight-bold'>K</span>ommerce</h1>
                    <Card className="shadow-sm p-4 border-0">
                        <Card.Body>
                            <div className="text-center mb-4">
                                {/* <img
                  src="https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg"
                  alt="Google Logo"
                  style={{ width: '120px' }}
                /> */}
                                <h2 className="mt-3">Créez votre compte EasyKommerce</h2>
                            </div>

                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="formFirstName" className="mb-3">
                                            <Form.Label className='font-weight-bold'>Prénoms</Form.Label>
                                            <Form.Control
                                                placeholder="Prénoms"
                                                type="text"
                                                name="firstName"
                                                value={formData.firstName}
                                                onChange={handleChange}
                                                isInvalid={!!formErrors.firstName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formErrors.firstName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="formLastName" className="mb-3">
                                            <Form.Label>Nom</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Nom"
                                                name="lastName"
                                                value={formData.lastName}
                                                onChange={handleChange}
                                                isInvalid={!!formErrors.lastName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formErrors.lastName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="formphone" className="mb-3">
                                            <Form.Label>Téléphone</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter your phone number"
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                isInvalid={!!formErrors.phone}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formErrors.phone}
                                            </Form.Control.Feedback>
                                            {/* <Form.Text className="text-muted">
                                                Entrez un numéro de téléphone à 10 chiffres.
                                            </Form.Text> */}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="formUsername" className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Adresse email"
                                                name="username"
                                                value={formData.username}
                                                onChange={handleChange}
                                                isInvalid={!!formErrors.username}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {formErrors.username}
                                            </Form.Control.Feedback>

                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group controlId="formstoreName" className="mb-3">
                                    <Form.Label>Entreprise</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nom de votre entreprise"
                                        name="storeName"
                                        value={formData.storeName}
                                        onChange={handleChange}
                                        isInvalid={!!formErrors.storeName}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formErrors.storeName}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Button
                                    variant="primary"
                                    className="w-100"
                                    type="submit"
                                    disabled={loading}
                                >
                                    {loading ? "Création de compte en cours..." : "S'inscrire"}
                                </Button>
                            </Form>

                            <div className="d-flex justify-content-between mt-3">
                                <Link to={'/login'}>Se connecter</Link>
                                <a href="#">Besoin d'aide?</a>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Register;
