import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import api from '../../api';
import { Button } from 'bootstrap';
import { Link } from 'react-router-dom';

function DepensesList() {

    const [depenses, setDepenses] = useState([]);

    const fetchListDepense = async () => {
        const response = await api.post('/Depenses/');
        if (response.data.status === 'OK') {
            setDepenses(response.data.result.depenses)

        }
    }
    useEffect(() => {
        fetchListDepense();
    }, []);

    return (
        <>
            <div class="content-wrapper">
                <div class="content-header">
                    <div class="container">
                        <div class="row mb-2">
                            <div class="col-sm-6 ">
                                <h2>Liste de Depenses</h2>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="card">
                                    <div className='p-3'><Link to={'/add-depense'} class='btn btn-light'><i class="fas fa-plus-circle"></i> Ajout Depense</Link></div>
                                    <div class="card-body">
                                        <DataTable value={depenses} tableStyle={{ minWidth: '50rem' }}>
                                            <Column field="title" header="Depense"></Column>
                                            <Column field="montant" header="Montant"></Column>
                                            <Column field="categorie_depense_id" header="Categorie"></Column>
                                            <Column field="created" header="Date"></Column>
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

export default DepensesList