import React, { useMemo } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineElement, PointElement } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';

// Enregistrez les composants Chart.js nécessaires
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement, // Enregistrement de PointElement pour les graphiques en ligne
  Title,
  Tooltip,
  Legend
);

const ReportsPage = () => {
  // Utilisation de useMemo pour mémoriser les données des graphiques
  const salesData = useMemo(() => ({
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Ventes (en milliers)',
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.6)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        data: [65, 59, 80, 81, 56, 55],
      },
    ],
  }), []);

  const stockData = useMemo(() => ({
    labels: ['Produit A', 'Produit B', 'Produit C', 'Produit D'],
    datasets: [
      {
        label: 'Quantité en stock',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: [50, 40, 30, 20],
      },
    ],
  }), []);
  return (
    <div class="content-wrapper">
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0">Rapport</h1>
            </div>

          </div>
        </div>
      </div>

      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col">
                  <Card>
                    <Card.Body>
                      <Card.Title>Rapport des Ventes</Card.Title>
                      <div style={{ height: '400px' }}> {/* Définir une hauteur fixe pour éviter le rendu infini */}
                        <Bar
                          data={salesData}
                          options={{
                            maintainAspectRatio: false,
                            responsive: true, // Assure la réactivité
                          }}
                        />
                      </div>
                      <Button variant="primary" className="mt-3">Voir le Rapport Complet</Button>
                    </Card.Body>
                  </Card>

                {/* Section Rapports de Stock */}
               
                  <Card>
                    <Card.Body>
                      <Card.Title>Rapport de Stock</Card.Title>
                      <div style={{ height: '400px' }}> {/* Définir une hauteur fixe pour éviter le rendu infini */}
                        <Line
                          data={stockData}
                          options={{
                            maintainAspectRatio: false,
                            responsive: true, // Assure la réactivité
                          }}
                        />
                      </div>
                      <Button variant="primary" className="mt-3">Voir le Rapport Complet</Button>
                    </Card.Body>
                  </Card>
                


                {/* Autres sections de rapports */}
              </div>
            </div>


          </div>

        </div>
      </div>

  );
};

export default ReportsPage;
